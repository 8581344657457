/* Questions  ##################################################################################################################### */

    .faq-section {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: transparent;
  }
  .faq-section .mbr-iconfont {
    font-size: 1.2rem !important;
    font-family: 'Moririse2' !important;
    color: white;
    transition: all 0.3s;
    transform: rotate(180deg);
  }
  .faq-section .panel-group {
    border: none;
  }
  .faq-section .card {
    background-color: transparent;
    border: none;
  }
  .faq-section .card-header {
    padding: 1.2rem 0.5rem;
  }
  @media (max-width: 767px) {
    .faq-section .card-header {
      padding: 1rem 0rem;
    }
  }
  .faq-section .panel-body {
    padding: 0 0.5rem;
    padding-bottom: 1rem;
  }
  @media (max-width: 767px) {
    .faq-section .panel-body {
      padding: 0rem;
      padding-bottom: 1rem;
    }
  }
  .faq-section .img-col {
    padding: 0;
  }
  .faq-section .img-item {
    height: 100%;
  }
  .faq-section img {
    height: 100%;
    object-fit: cover;
  }
  .faq-section .collapsed span {
    transform: rotate(0deg);
  }
  .faq-section .panel-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .faq-section p {
    margin-bottom: 0.3rem;
  }
  .faq-section .card .card-header {
    background-color: transparent;
    margin-bottom: 0;
    border: 0;
    border-radius: 2rem;
  }
  .faq-section .card {
    background: #ffffff;
    padding: 1rem 2rem;
    border-radius: 2rem;
  }
  @media (max-width: 767px) {
    .faq-section .card {
      padding: 1.5rem;
    }
  }
  .faq-section .panel-text {
    color: #000000;
  }
  .faq-section .mbr-section-title {
    text-align: center;
    color: #000000;
  }
  .faq-section .mbr-section-subtitle {
    color: #000000;
    text-align: center;
  }
  .faq-section .panel-title-edit,
  .faq-section .mbr-iconfont {
    color: #000000;
  }
