.modal {
    z-index: 1050; /* The modal is above the backdrop */
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1040; /* Make sure the overlay is behind the modal */
}

.pathway-figure {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.pathway-figure img {
    display: block;
    transition: filter 0.1s ease;
}

.pathway-figure:hover img {
    filter: grayscale(100%) brightness(50%);
}

/* Add "+" but without displaying it */
.pathway-figure::after {
    content: "+";
    font-size: 48px;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.1s ease;
}

/* Display "+" on hover */
.pathway-figure:hover::after {
    opacity: 1;
}