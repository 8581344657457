/* Contact_us  ##################################################################################################################### */

.contact-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: transparent;
}
.contact-section .mbr-text {
  color: #000000;
}
.contact-section .mbr-section-subtitle {
  color: #000000;
}
.contact-section .main-button {
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .contact-section .main-button {
    margin-bottom: 2rem;
  }
}
.contact-section .mbr-section-subtitle,
.contact-section .main-button {
  color: #000000;
}